export default [
  {
    title: 'Agents',
    route: 'agency-agents-index',
    icon: 'agents.svg',
    customIcon: true,
    resource: 'Agent',
    action: 'read',
    parent: 'agencies',
  },
  {
    title: 'Agent Requests',
    route: 'agencies-agent-profile-update-index',
    icon: 'AlertCircleIcon',
    parent: 'agencies',
    resource: 'AgentRequest',
    action: 'read',
  },
  // {
  //   header: 'Announcements',
  //   icon: 'MessageCircleIcon',
  //   parent: 'agencies',
  //   key: 'agencies announcement categories',
  //   children: [
  //     {
  //       title: 'Announcements',
  //       route: 'agencies-announcements-index',
  //       icon: '',
  //       params: {
  //         type: 'all-announcement',
  //       },
  //       resource: 'Announcement',
  //       action: 'read',
  //     },
  //     {
  //       title: 'Pending Verification',
  //       route: 'agencies-announcements-index',
  //       icon: '',
  //       params: {
  //         type: 'pending',
  //       },
  //       resource: 'Announcement',
  //       action: 'read',
  //     },
  //     {
  //       title: 'Announcement Categories',
  //       route: 'agencies-announcement-categories-index',
  //       icon: '',
  //       params: {
  //         type: 'all-announcement-category',
  //       },
  //       resource: 'AnnouncementCategory',
  //       action: 'read',
  //     },
  //   ],
  // },
  {
    header: 'FAQ',
    icon: 'HelpCircleIcon',
    parent: 'agencies',
    key: 'agencies faq',
    children: [
      {
        title: 'Articles',
        route: 'agencies-articles-index',
        icon: '',
        params: {
          type: 'all-article',
        },
        resource: 'AgentArticle',
        action: 'read',
      },
      {
        title: 'Article Categories',
        route: 'agencies-article-categories-index',
        icon: '',
        params: {
          type: 'all-article-category',
        },
        resource: 'AgentArticleCategory',
        action: 'read',
      },
    ],
  },
]
